import React, { useState, useEffect, useRef } from "react";
import Banner from "./Banner";
import TerminalOutput from "./TerminalOutput";
import InputArea from "./InputArea";
import ErrorMessage from "./ErrorMessage";
import WelcomeMessage from "./WelcomeMessage";
import useSound from "use-sound";
import happyBirthdayFx from "../assets/happy-birthday.mp3";

// Just a little helper function so I don't have to continually update my age
const getAge = (birthDate) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

const downloadFile = (uri, downloadName) => {
    const link = document.createElement("a");
    link.download = downloadName;
    link.href = uri;
    link.click();
    link.remove();
};

const birthdayCake =
    "                                   /^\\\n" +
    "                         /         (/^\\)     /\n" +
    "                    \\   ( \\         \\ /     ( \\     /^\\\n" +
    "                   / )   \\ |        _|_      \\ |   |/^\\|\n" +
    "                  | /    _|_        | |      _|_    \\ /\n" +
    "                  _|_    | |        | |      | |    _|_\n" +
    "                  | |    | |        | |      | |    | |\n" +
    "                  | |    | |    ****| |******| |    | |\n" +
    "                  | |****| |****    | |      | |****| |\n" +
    "                 *| |    | |                 | |    | |*****\n" +
    "               *  | |   H  A  P  P  Y               | |      *\n" +
    "              *                                               *\n" +
    "              | *            B  I  R  T  H  D  A  Y  !      * |\n" +
    "              |  *****                                 *****  |\n" +
    "              |@      **********             **********      @|\n" +
    "              | @   @           *************           @   @ |\n" +
    "              |  @@@ @    @                       @    @ @@@  |\n" +
    "              |       @@@@ @      @       @      @ @@@@       |\n" +
    "               *            @@@@@@ @     @ @@@@@@            *\n" +
    "                *                   @@@@@                   *\n" +
    "                 *****                                 *****\n" +
    "                      **********             **********\n" +
    "                                *************\n" +
    "\n" +
    "                      /^--^\\     /^--^\\     /^--^\\\n" +
    "                      \\____/     \\____/     \\____/\n" +
    "                     /      \\   /      \\   /      \\\n" +
    "                    |        | |        | |        |\n" +
    "                     \\__  __/   \\__  __/   \\__  __/\n" +
    "|^|^|^|^|^|^|^|^|^|^|^|^\\ \\^|^|^|^/ /^|^|^|^|^\\ \\^|^|^|^|^|^|^|^|^|^|^|^|\n" +
    "| | | | | | | | | | | | |\\ \\| | |/ /| | | | | | \\ \\ | | | | | | | | | | |\n" +
    "########################/ /######\\ \\###########/ /#######################\n" +
    "| | | | | | | | | | | | \\/| | | | \\/| | | | | |\\/ | | | | | | | | | | | |\n" +
    "|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_| \n" +
    "---------------------------------------------------------------------------\n" +
    "NICE MUTLU YILLARA BESTE !!! \n" +
    "---------------------------------------------------------------------------\n" +
    "CTF(imsi) etkiligimizin sonuna geldik! \n\n" +
    "Bu mesaji buldugunda bana haber verirsen \n" +
    "sitede senin icin olan bulmaca kisimlarini kaldirip websitesini public yapabiliriz :) \n" +
    "Ben hazirlarken cok keyif aldim, umarim ki sen de cozerken keyif almissindir :D  \n" +
    "---------------------------------------------------------------------------\n";


const Terminal = (props) => {
    const { terminalPrompt = ">", banner, welcomeMessage } = props;
    const [output, setOutput] = useState([]);
    const [history, setHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(3);
    const inputRef = useRef(null);
    const scrollRef = useRef(null);

    const scrollLastCommandTop = () => {
        scrollRef.current?.scrollIntoView();
    };

    useEffect(scrollLastCommandTop, [output]);

    const echoCommands = [
        "help",
        "about",
        "experience",
        "contact",
        "certs",
        "skills",
    ];
    const utilityCommands = ["clear", "all"];
    const allCommands = [...echoCommands, ...utilityCommands];
    const specialCommands = ['sudo', 'very-secret-command', 'riddle', 'sudo very-secret-command', 'hint'];

    function isSpecialCommand(arg) {
        return specialCommands.includes(arg);
    }

    function isEchoCommand(arg) {
        return echoCommands.includes(arg);
    }

    function isUtilityCommand(arg) {
        return utilityCommands.includes(arg);
    }

    function isValidCommand(arg) {
        return isSpecialCommand(arg) || isEchoCommand(arg) || isUtilityCommand(arg);
    }

    const glow = (text) => {
        return <span className="terminal-glow">{text}</span>;
    };

    const commands = {
        help: (
            <div>
                <p>
                    Type any of the commands below to get some more info. You can even type a few letters and press [tab]
                    or '.' to autocomplete.
                </p>
                <dl>
                    <dt>about</dt>
                    <dd>A quick biography of me</dd>
                    <dt>experience</dt>
                    <dd>Summary of my journey so far...</dd>
                    <dt>skills</dt>
                    <dd>I'm pretty good at some things</dd>
                    <dt>certs</dt>
                    <dd>List of the certificates I have got</dd>
                    {/*<dt>download_cv</dt>*/}
                    {/*<dd>Check out my CV [pdf - 168KB]</dd>*/}
                    <dt>contact</dt>
                    <dd>Bring on the spam</dd>
                    <dt>all</dt>
                    <dd>Tell me everything</dd>
                    <dt>clear</dt>
                    <dd>Clears the terminal of all output</dd>
                </dl>
            </div>
        ),
        about: (
            <>
                <div>
                    <p>
                        Beste has received B.S. and M.S. degrees in Computer Science and Engineering
                        from Sabanci University, in 2016 and 2019, respectively. Her research
                        thesis was about secure key agreement between biosensors. Her
                        research interests include computer and network security, data and
                        communication security and biometrics. After receiving her Masters
                        degree, she started to work as a Security Analyst at Sony Electronics,
                        in Istanbul, Turkey. At Sony, she worked on web application
                        penetration testing process, research of security testing tools and
                        methods, and the evaluation of bug bounty findings. In her spare time,
                        she likes to play capture the flag games and boardgames.
                    </p>
                </div>
            </>
        ),
        experience: (
            <>
                <dl>
                    <dt>Cyber Security Senior Specialist @ Vodafone</dt>
                    <dd>Dec 2020 - Current</dd>
                    <dt>Security Analyst @ Sony Electronics</dt>
                    <dd>May 2019 - Dec 2020</dd>
                    <dt>Teaching Assistant @ Sabanci University</dt>
                    <dd>Sep 2016 - Dec 2018</dd>
                    <dt>Software Development Intern @ Akbank</dt>
                    <dd>Aug 2015 - Sep 2015</dd>
                </dl>
            </>
        ),
        skills: (
            <>
                <ul>
                    <li>Ethical Hacking</li>
                    <li>Security Analysis</li>
                    <li>Network Security</li>
                    <li>Web Application Security</li>
                    <li>Penetration Testing</li>
                    <li>Cryptography</li>
                    <li>Python</li>
                    <li>Javascript</li>
                    <li>Java</li>
                    <li>Kali</li>
                </ul>
            </>
        ),
        certs: (
            <>
                <dl>
                    <dt>Offensive Security Certified Professional (OSCP)</dt>
                    <dd>Offensive Security</dd>
                    <dd>Issued Jan 2022</dd>
                </dl>
                <dl>
                    <dt>Certified Ethical Hacker (CEH)</dt>
                    <dd>EC Council</dd>
                    <dd>Issued Oct 2019 - Expr Oct 2022</dd>
                </dl>
            </>
        ),
        contact: (
            <>
                <dl>
                    <dt>Email</dt>
                    <dd>
                        <a href="mailto:beste.seymen@gmail.com">beste.seymen [at] gmail.com</a>
                    </dd>
                    <dt>LinkedIn</dt>
                    <dd><a href="https://www.linkedin.com/in/besteseymen/">https://www.linkedin.com/in/besteseymen/</a></dd>
                </dl>
            </>
        ),
    };

    const [play, { stop }] = useSound(
        happyBirthdayFx,
        { volume: 1 }
    );

    const processCommand = (input) => {
        // Store a record of this command with a ref to allow us to scroll it into view.
        // Note: We use a ref callback here because setting the ref directly, then clearing output seems to set the ref to null.
        const commandRecord = (
            <div
                ref={scrollRef}
                className="terminal-command-record"
            >
                <span className="terminal-prompt">{terminalPrompt}</span>{" "}
                <span>{input}</span>
            </div>
        );

        // Add command to to history if the command is not empty
        if (input.trim()) {
            setHistory([...history, input]);
            setHistoryIndex(history.length + 1);
        }

        // Now process command, ignoring case
        const inputCommand = input.toLowerCase();
        if (!isValidCommand(inputCommand)) {
            setOutput([
                ...output,
                commandRecord,
                <div className="terminal-command-output">
                    <ErrorMessage command={inputCommand} />
                </div>,
            ]);
        } else if (isSpecialCommand(inputCommand)) {
            switch (inputCommand) {
                case "hint":
                    setOutput([
                        ...output,
                        commandRecord,
                        <div className="terminal-command-output">
                            That looks a familiar encoding, especially with that = suffix code.
                        </div>,
                    ])
                    break;
                case "riddle":
                    setOutput([
                        ...output,
                        commandRecord,
                        <div className="terminal-command-output">
                            WW91IGFyZSBib3JuIHRvIGJlIHJvb3QuIENhbiB5b3UgbWFrZSBtZSBydW4gdGhpcyBjb21tYW5kID8KInZlcnktc2VjcmV0LWNvbW1hbmQi=
                        </div>,
                    ])
                    break;
                case "sudo":
                    setOutput([
                        ...output,
                        commandRecord,
                        <div className="terminal-command-output">
                            Does not mean much by itself.
                        </div>,
                    ])
                    break;
                case "very-secret-command":
                    setOutput([
                        ...output,
                        commandRecord,
                        <div className="terminal-command-output">
                            Nope.
                        </div>,
                    ])
                    break;
                case "sudo very-secret-command":
                    setOutput([]);
                    play();
                    setOutput([
                        ...output,
                        commandRecord,
                        <div className="terminal-command-output">
                            {birthdayCake}
                        </div>,
                    ])
                    break;
            }
        } else if (isEchoCommand(inputCommand)) {
            setOutput([
                ...output,
                commandRecord,
                <div className="terminal-command-output">{commands[inputCommand]}</div>,
            ]);
        } else if (isUtilityCommand(inputCommand)) {
            switch (inputCommand) {
                case "clear": {
                    setOutput([]);
                    break;
                }
                case "all": {
                    // Output all commands in a custom order.
                    const allCommandsOutput = [
                        "about",
                        "experience",
                        "skills",
                        "certs",
                        "contact",
                    ].map((command) => (
                        <>
                            <div>
                                <span className="terminal-prompt">--</span>{" "}
                                <span>{command}</span>
                            </div>
                            <div className="terminal-command-output">
                                {commands[command]}
                            </div>
                        </>
                    ));

                    setOutput([commandRecord, ...allCommandsOutput]);
                    break;
                }
                case "download_cv": {
                    setOutput([...output, commandRecord]);
                    downloadFile("CV.pdf", "Beste Seymen - CV.pdf");
                    break;
                }
            }
        }
    };

    const getHistory = (direction) => {
        let updatedIndex;
        if (direction === "up") {
            updatedIndex = historyIndex === 0 ? 0 : historyIndex - 1;
        } else {
            updatedIndex =
                historyIndex === history.length ? history.length : historyIndex + 1;
        }
        setHistoryIndex(updatedIndex);
        return updatedIndex === history.length ? "" : history[updatedIndex];
    };

    const getAutocomplete = (input) => {
        const matchingCommands = allCommands.filter((c) => c.startsWith(input));
        if (matchingCommands.length === 1) {
            return matchingCommands[0];
        } else {
            const commandRecord = (
                <div
                    ref={scrollRef}
                    className="terminal-command-record"
                >
                    <span className="terminal-prompt">{terminalPrompt}</span>{" "}
                    <span>{input}</span>
                </div>
            );
            setOutput([...output, commandRecord, matchingCommands.join("    ")]);
            return input;
        }
    };

    const focusOnInput = (event) => {
        if (event.key === "Tab") {
            // Prevent tab from moving focus
            event.preventDefault();
        }
        inputRef.current?.focus();
    };

    return (
        <div className="terminal-container" tabIndex={-1} onKeyDown={focusOnInput}>
            <div className="terminal-content">
                {banner && <Banner banner={banner} />}
                {welcomeMessage && (
                    <WelcomeMessage message={welcomeMessage} inputRef={inputRef} />
                )}
                <TerminalOutput outputs={output} />
                <InputArea
                    setOutput={setOutput}
                    processCommand={processCommand}
                    getHistory={getHistory}
                    getAutocomplete={getAutocomplete}
                    inputRef={inputRef}
                    terminalPrompt={terminalPrompt}
                />
            </div>
        </div>
    );
};

export default Terminal;
